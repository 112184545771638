export enum AccountSetting {
  SLS = 'Success Page Adaption SLS',
}

export enum IconType {
  CheckIcon = 'check',
  CheckAverageIcon = 'check-average',
  CloseIcon = 'close',
  FavIcon = 'favicon',
  CheckAveragePart = '-average',
}

export enum IconColor {
  Green = '#39aa36',
  Yellow = '#f0ca45',
  Red = 'red',
}

export enum ResultConfigurationConflict {
  InProgress = 'IN_PROGRESS',
}

export enum KeyName {
  Enter = 'Enter',
}

export enum ContractType {
  NotPrevious = 1,
  KnownPrevious = 2,
  UnknownPrevious = 3,
}

export enum URLPart {
  Comparison = '/comparison',
  Consultation = '/consultation',
  Booking = '/booking',
  Offer = '/offer',
  OfferId = 'offerId',
  Broker = '/broker',
  Product = '/product',
  Tender = '/tender',
  Result = '/result',
  Inquiry = '/inquiry',
  Questionnaire = '/questionnaire',
  Dashboard = '/dashboard',
  BrokerDealBreak = '/dealBreak',
  Request = '/request',
  New = '/new',
  Success = '/success',
  ReadOnlyQuestionnaire = '/read-only-questionnaire',
  BrokerHash = 'bHash',
  InquiryHash = 'inquiryHash',
  InquiryGroupHash = 'inquiryGroupHash',
  BrokerCode = 'brokerCode',
  ProductTag = 'productTag',
  ErrorPage = '/error',
}

export enum ErrorsURLPart {
  Type403 = '/error?type=403',
  Type503 = '/error?type=503',
}

export enum PaymentIntervalValue {
  Month = '1',
  Quarter = '3',
  HalfYear = '6',
  Year = '12',
}

export enum PremiumPaymentIntervals {
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'halfyear',
  Year = 'year',
}

export enum ProfessionsPart {
  Aliases = '/profession-aliases/',
}

export enum ProfessionList {
  All = 'all',
}

export enum ProfessionEnum {
  anotherProfessionHash = 'cb1bad04',
  anotherProfessionName = 'Andere/sonstige Tätigkeit',
}

export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}
export enum SessionStorage {
  Iframe = 'iframe',
  Customer = 'customer',
}

export enum UserRoles {
  B2B = 'b2b',
  B2C = 'b2c',
}

export enum BookingStep {
  Booked = 'booked',
  CustomerInfo = 'customerInformation',
  Details = 'details',
  DocumentSetup = 'documentSetup',
  EmailSetup = 'emailSetup',
  ReviewConfirm = 'reviewAndConfirm',
  Questionnaire = 'questionnaire',
  Signing = 'signing',
}

export enum BookingReviewHeader {
  AgencyNumber = 'agencyNumber',
  BrokerData = 'brokerData',
  CustomerInfo = 'customerInformation',
  PaymentDetails = 'paymentDetails',
  DocumentSetup = 'documentSetup',
  EmailSetup = 'emailSetup',
  SelectedTariff = 'selectedTariff',
  Questionnaire = 'questionnaire',
  RiskQuestions = 'riskQuestions',
}

export enum BookingInputNamePart {
  Files = 'files',
}
export enum BookingFormTitle {
  AgencyNumber = 'agencyNumber',
  BankDetails = 'bankDetails',
  PaymentDetails = 'paymentDetails',
  ConfigureInsurerEmail = 'configureInsurerEmail',
  ConfigureCustomerEmail = 'configureCustomerEmail',
  ConfigureCustomerEmailDocs = 'configureCustomerEmailDocs',
  ConfigureCustomerEmailCheckbox = 'configureCustomerEmailCheckbox',
  ConfigureCustomerEmailInsurerDocs = 'configureCustomerEmailInsurerDocs',
  ConfigureConfirmationEmail = 'configureConfirmationEmail',
  ConfigureConfirmationEmailInsurerDocs = 'configureConfirmationEmailInsurerDocs',
  ReviewAndConfirmCheckboxes = 'reviewAndConfirmCheckboxes',
}
export enum BookingFormSubTitle {
  GeneralDocuments = 'generalDocuments',
}
export enum BookingInputName {
  IncludeCoverLetter = 'blockProposalDocumentIncludeCoverLetter',
  PaymentInterval = 'frequency',
  PaymentMethod = 'paymentMethod',
  PaymentAuthorization = 'paymentAuthorization',
  ProposalDocumentComposeMessage = 'blockProposalDocumentComposeMessageArea',
  LogoUpload = 'logoUpload',
  InsurerEmailUpload = 'insurerEmailUpload',
  LogoUploadCheckbox = 'useLogoUpload',
  InsurerEmailUploadCheckbox = 'useInsurerEmailUpload',
  DifferentPostalAddress = 'differingPostalAddress',
  PostalCity = 'postalCity',
  PostalAddressStreet = 'postalAddressStreet',
  PostalAddressNumber = 'postalAddressNo',
  PostalCode = 'postalPostalCode',
  InsuranceTermsConfirmation = 'insuranceTermsConfirmation',
  BrokerMandateConfirmation = 'brokerMandateConfirmation',
  PreContractualObligation = 'preContractualObligation',
  GeneralDataProcessingConfirmation = 'generalDataProcessingConfirmation',
  BrokerMandateUpload = 'brokerMandateUpload',
  RiskAnalysisUpload = 'riskAnalysisUpload',
  SendCustomerEmail = 'sendCustomerEmail',
  FurtherBookingDocUpload = 'furtherBookingDocUpload',
  CustomerBrokerageAgreementDocUpload = 'customerBrokerageAgreementDoc',
  ExternalConsultationProtocollDocUpload = 'externalConsultationProtocollDoc',
  CustomerContactPersonEmail = 'customerContactPersonEmail',
  EmailCc = 'emailCc',
  EmailBody = 'emailBody',
  PayerType = 'payerType',
  BankIban = 'bankIban',
  BankBic = 'bankBic',
  BankName = 'bankName',
  PayerCompanyName = 'payerCompanyName',
  PayerCompanyLegalForm = 'payerCompanyLegalForm',
  PayerFirstName = 'payerFirstName',
  PayerLastName = 'payerLastName',
  PayerStreet = 'payerStreet',
  PayerStreetNumber = 'payerStreetNumber',
  PayerPostcode = 'payerPostcode',
  PayerCity = 'payerCity',
  BankAccountHolder = 'bankAccountHolder',
  PayerSalutation = 'payerSalutation',
}
export enum BookingInquiryHash {
  General = 'general',
}

export enum BookingBlockWithAccordion {
  ConfigureConsultationProtocol = 'configureConsultationProtocol',
  ConfigureConfirmationEmailInsurerDocs = 'configureConfirmationEmailInsurerDocs',
  ConfigureCustomerEmailInsurerDocs = 'configureCustomerEmailInsurerDocs',
  EditBrokerInformation = 'editBrokerInformation',
}

export enum BookingParamKey {
  FlowType = 'flowType',
}

export enum UnlimitedValue {
  Value = 123123123,
}

export enum StateDisplayName {
  global = 'GlobalStateContext',
  main = 'MainStateContext',
}

export enum ProductTag {
  CompanyLow = 'firmenrecht',
  Building = 'gebaeude',
}

export enum ToolbarOption {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  removeformat = 'removeformat',
  variables = 'variables',
  bullist = 'bullist',
  numlist = 'numlist',
  outdent = 'outdent',
  indent = 'indent',
  table = 'table',
}

// TODO: find better way to keep setting ids
export enum AccountSettingId {
  RiskAnalysisRiskAssessment = '102',
  ResultPageHideFilterColumn = '213',
  ResultPageHideFilterForSingleInsurerOffer = '214',
  ResultPageHideSortingFunctionality = '215',
  ResultPageHideHideComparisonAndOfferRangeFunctionality = '216',
  ResultPageHideShowOfferDetailsAsDefault = '217',
  ConsultDirectHideProfessionAliasInProgressIndicator = '226',
  HideThinksuranceNameInFooter = '232',
  BookingHidePaymentDetailsSection = '233',
}

export enum PortalSettingsValue {
  Enabled = '1',
  Disabled = '0',
}

export enum YesNoValue {
  Yes = 'Ja',
  No = 'Nein',
}

export enum CookiesEnum {
  ShowBrokerTutorialModal = 'showBrokerTutorial',
}

export enum SessionStatus {
  Loading = 'loading',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

export enum Step {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum PortalsId {
  BookingFormSubBlockTitlePortal = 'booking-sub-block-title',
}

export enum CategoryRank {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export enum Partners {
  Wert14 = 'Wert14',
}

export enum FilterValues {
  All = 'all',
  ShowBestOfferPerTariffOnly = 'showBestOfferPerTariffOnly',
  ResultPage = 'resultPage',
}

export enum FilterType {
  RadioButton = 'radio-button',
  RadioButtonMin = 'radio-button-min',
  RadioButtonMax = 'radio-button-max',
  RadioButtonMoney = 'radio-button-money',
  RadioButtonMoneyMin = 'radio-button-money-min',
  RadioButtonMoneyMax = 'radio-button-money-max',
  CheckboxPeriodYearly = 'checkbox-period-yearly',
  Checkbox = 'checkbox',
}

export enum TenderRequestType {
  BackOffice = 2,
  BrokerManaged = 3,
}

export enum InquiryStatus {
  OfferAvailable = 'offerAvailable',
  Ordered = 'ordered',
  Cancelled = 'cancelled',
}

export enum DocumentsType {
  ProposalDocument = 'proposalDocument',
  ProposalOrderDocument = 'proposalOrderDocument',
  ProposalConsultationDocument = 'proposalConsultationDocument',
  SepaDocument = 'sepaDocument',
  InsurerDocument = 'insurer',
  QuestionnaireDocument = 'questionnaire',
  InsurerAttachmentsDocument = 'insurerEmailUpload',
  OrderDocument = 'orderDocument',
  ConsultationDocument = 'consultationDocument',
  RiskAnalysisDocument = 'riskAnalysisUpload',
  BrokerMandateDocument = 'brokerMandateUpload',
  FurtherBookingDocument = 'furtherBookingDocUpload',
  CustomerBrokerageAgreementDocument = 'customerBrokerageAgreementDoc',
  ExternalConsultationProtocollDocument = 'externalConsultationProtocollDoc',
}

export enum KeyToOrderArrayBy {
  Type = 'type',
}

export enum ErrorType {
  Manual = 'manual',
}

export enum DiscountType {
  AccountDiscount = 'account-discount',
  BookingDiscount = 'booking-discount',
  TargetDiscount = 'target-discount',
}
const MB = 1024 * 1024;

export enum FileSizes {
  MB_10 = 10 * MB,
  MB_5 = 5 * MB,
  MB_3 = 3 * MB,
}

export enum HttpHeader {
  ContentType = 'content-type',
  Accept = 'accept',
}

export enum AxiosResponseType {
  ArrayBuffer = 'arraybuffer',
  Document = 'document',
  Json = 'json',
  Text = 'text',
  Stream = 'stream',
  Blob = 'blob',
}

export enum ContentType {
  Pdf = 'application/pdf',
  Json = 'application/json',
}

export enum FileParsingErrorType {
  NotDownloadable = 'file.notDownloadable',
  EmptyFile = 'file.empty',
  ParsingFailed = 'file.parsingFailed',
  WrongNumberOfFields = 'file.wrongNumberOfFields',
  MappingTypeNotFound = 'mappingType.notFound',
  MappingNotFound = 'mapping.notFound',
  FieldsNotUnique = 'fields.notUnique',
  DelimiterNotSemicolon = 'delimiter.notSemicolon',
  AmountTooHigh = 'categories.amountTooHigh',
}

export enum OfferTypes {
  TenderingOffer = 'tenderingOffer',
}

export enum OfferStatus {
  Released = 'released',
}

export enum FilterKeyParts {
  FilterFeatures = 'filter[features]',
  FilterStatus = 'filter[status]',
  FilterValue = '[value]',
  FilterPrefix = 'filter',
}

export enum SortingKeyParts {
  SortingPrefix = 'order',
}

export enum ToastId {
  InquiryFailed = 'createInquiryFailed',
}

export enum PayerTypeOptions {
  ThisCompanyOrCustomer = 'customer',
  AnotherCompany = 'differentCompany',
  SomeoneElse = 'differentPrivate',
}

export enum Locale {
  germany = 'de',
  france = 'fr',
}

export enum BrowserList {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edg',
}

export enum ComparisonCategory {
  FrankeAndBornbergRating = '181',
}
